import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs"
import ImageChirurgieEpauleAccueil from "./imageChirurgieEpauleAccueil"
import ImageChirurgieCoudeAccueil from "./imageChirurgieCoudeAccueil"
import ImageChirurgiePoignetAccueil from "./imageChirurgiePoignetAccueil"
import ImageChirurgieMainAccueil from "./imageChirurgieMainAccueil"
import "react-web-tabs/dist/react-web-tabs.css"

export default () => (
  <Wrapper>
    <Global
      styles={css`
        .rwt__tabs {
          margin: 1em 0;
        }
        .rwt__tab {
          width: 25%;
          padding: 2rem 3rem 1.25rem;
        }
        .rwt__tab[aria-selected="true"] {
          div:nth-child(2) {
            border-bottom: 2px solid #d69c31;
          }
        }
        .rwt__tab[aria-selected="false"] {
          filter: grayscale(100%);
        }
        .rwt__tab[aria-selected="false"]:hover,
        .rwt__tab:focus {
          background-color: inherit;
        }
        .rwt__tablist:not([aria-orientation="vertical"])
          .rwt__tab[aria-selected="true"]:after {
          border-bottom: none;
        }
        .rwt__tablist:not([aria-orientation="vertical"]) {
          border-bottom: none;
        }
        .rwt__tabpanel {
          padding: 0em 3em 2em;
          min-height: 275px;
          p {
            font-weight: 500;
          }
        }
        @media (max-width: 1024px) {
          .rwt__tab {
            padding: 2rem 1.5rem 1.25rem;
          }
        }
        @media (max-width: 678px) {
          .rwt__tabpanel {
            padding: 0;
            margin-top: 25px;
            p {
              font-weight: inherit;
            }
          }
          .rwt__tab {
            padding: 0 7px 0;
          }
          .rwt__tabs {
            margin-top: 35px;
          }
        }
      `}
    />
    <H2>
      <span>Opérations</span> pratiquées
    </H2>
    <hr />
    <Tabs defaultTab="one">
      <TabList>
        <Tab tabFor="one">
          <ImageChirurgieEpauleAccueil />
          <Cat>Épaule</Cat>
        </Tab>
        <Tab tabFor="two">
          {" "}
          <ImageChirurgieCoudeAccueil />
          <Cat>Coude</Cat>
        </Tab>
        <Tab tabFor="three">
          <ImageChirurgiePoignetAccueil />
          <Cat>Poignet</Cat>
        </Tab>
        <Tab tabFor="four">
          <ImageChirurgieMainAccueil />
          <Cat>Main</Cat>
        </Tab>
      </TabList>
      <TabPanel tabId="one">
        <P>
          La compréhension des lésions touchant l'articulation de l'épaule et
          les possibilités de traitement qui peuvent leur être apportées ont
          fait d'énormes progrès ces 15 dernières années, grâce aux moyens
          d'imagerie comme l'IRM et l'arthroscanner, et au développement de
          nouvelles techniques de chirurgie arthroscopique de l'épaule.
        </P>
        <LinkList>
          <StyledLink to="/chirurgie-epaule/arthrose-acromio-claviculaire/">
            Arthrose acromio-claviculaire de l'épaule
          </StyledLink>
          <StyledLink to="/chirurgie-epaule/conflit-sous-acromial/">
            Conflit sous-acromial (Acromioplastie)
          </StyledLink>
          <StyledLink to="/chirurgie-epaule/fracture-clavicule/">
            Fracture de la clavicule
          </StyledLink>
          <StyledLink to="/chirurgie-epaule/luxation-acromio-claviculaire-luxation-de-la-clavicule/">
            Luxation acromio-claviculaire / Luxation de la clavicule
          </StyledLink>
          <StyledLink to="/chirurgie-epaule/luxation-epaule/">
            Luxation de l'épaule
          </StyledLink>
          <StyledLink to="/chirurgie-epaule/omarthrose-arthrose-epaule/">
            Omarthrose - Arthrose de l’épaule
          </StyledLink>
          <StyledLink to="/chirurgie-epaule/operation-bankart/">
            Opération de Bankart
          </StyledLink>
          <StyledLink to="/chirurgie-epaule/operation-latarjet/">
            Opération de Latarjet
          </StyledLink>
          <StyledLink to="/chirurgie-epaule/prothese-epaule/">
            Prothèse de l’épaule
          </StyledLink>
          <StyledLink to="/chirurgie-epaule/rupture-coiffe-des-rotateurs/">
            Rupture de la coiffe des rotateurs
          </StyledLink>
          <StyledLink to="/chirurgie-epaule/tendinopathie-coiffe-rotateurs/">
            Tendinopathie de la coiffe des rotateurs
          </StyledLink>
          <StyledLink to="/videos/conseils-apres-acromioplastie-epaule/">
            Conseils après acromioplastie de l'épaule
          </StyledLink>
          <StyledLink to="/videos/conseils-apres-reinsertion-tendons-coiffe-rotateurs/">
            Conseils après réinsertion des tendons de la coiffe des rotateurs
          </StyledLink>
        </LinkList>
        <LinkButton
          to="/chirurgie-epaule/"
          title="En savoir plus sur la chirurgie orthopédique de l'épaule"
        >
          En savoir plus
        </LinkButton>
      </TabPanel>
      <TabPanel tabId="two">
        <P>
          Chacun d'entre nous peut développer des douleurs chroniques et
          invalidantes du coude, souvent dues à une épicondylite, une
          épitrochléite ou aux suites d'un traumatisme.
        </P>
        <P>
          Une perte de sensibilité ou de force dans la main peut aussi être
          provoquée par une compression du nerf cubital au niveau du coude.
        </P>
        <P>
          Les techniques de chirurgie arthroscopique du coude ont là aussi
          beaucoup fait progresser les possibilités de traitement.
        </P>
        <LinkList>
          <StyledLink to="/chirurgie-coude/epicondylite-tendinite-coude/">
            Épicondylite&nbsp;: Tendinite du coude
          </StyledLink>
          <StyledLink to="/chirurgie-coude/epitrochleite-tendinite-mediale/">
            Épitrochléite&nbsp;: Tendinite médiale du coude
          </StyledLink>
          <StyledLink to="/chirurgie-coude/rupture-biceps/">
            Rupture du biceps
          </StyledLink>
          <StyledLink to="/chirurgie-coude/compression-nerf-cubital/">
            Syndrome de compression du nerf cubital
          </StyledLink>
        </LinkList>
        <LinkButton
          to="/chirurgie-coude/"
          title="En savoir plus sur la chirurgie orthopédique du coude"
        >
          En savoir plus
        </LinkButton>
      </TabPanel>
      <TabPanel tabId="three">
        <P>
          De nombreux patients se plaignent de douleurs chroniques ou post
          traumatiques du poignet, sans diagnostic précis autre qu’« entorse »,
          « tendinite » ou « fracture ». Néanmoins, ces lésions du poignet
          peuvent être graves, et entrainer des douleurs importantes ainsi
          qu’une limitation lors des gestes de la vie quotidienne ou d’activités
          sportives.
        </P>
        <P>
          Les récentes avancées techniques permettent au chirurgien du poignet
          de proposer un diagnostic et une prise en charge plus précise ainsi
          qu’une récupération postopératoire plus rapide.
        </P>
        <LinkList>
          <StyledLink to="/chirurgie-poignet/arthroscopie-poignet/">
            Arthroscopie du poignet
          </StyledLink>
          <StyledLink to="/chirurgie-poignet/entorse-poignet/">
            Entorse du poignet
          </StyledLink>
          <StyledLink to="/chirurgie-poignet/fracture-du-poignet/">
            Fracture du poignet
          </StyledLink>
          <StyledLink to="/chirurgie-poignet/fracture-scaphoide/">
            Fracture du scaphoïde
          </StyledLink>
          <StyledLink to="/chirurgie-poignet/kyste-synovial-poignet/">
            Kyste synovial du poignet
          </StyledLink>
          <StyledLink to="/chirurgie-poignet/maladie-de-kienbock/">
            Maladie de Kienböck
          </StyledLink>
          <StyledLink to="/chirurgie-poignet/pseudarthrose-du-scaphoide/">
            Pseudarthrose du scaphoïde
          </StyledLink>
          <StyledLink to="/chirurgie-poignet/tendinite-poignet/">
            Tendinite de De Quervain
          </StyledLink>
          <StyledLink to="/videos/conseils-apres-chirurgie-poignet/">
            Conseils après chirurgie du poignet
          </StyledLink>
        </LinkList>
        <LinkButton
          to="/chirurgie-poignet/"
          title="En savoir plus sur la chirurgie orthopédique du poignet à Paris"
        >
          En savoir plus
        </LinkButton>
      </TabPanel>
      <TabPanel tabId="four">
        <P>
          La chirurgie de la main reste une discipline à part au sein de la
          chirurgie orthopédique. En effet, la main est un organe essentiel par
          sa fonction mais également par son rôle social.
        </P>
        <P>
          La main est une zone anatomique où se côtoient dans un espace réduit
          de multiples structures différentes et mobiles, comme les nerfs, les
          tendons, les vaisseaux...
        </P>
        <P>
          Sa prise en charge nécessite un savoir-faire particulier et très
          spécialisé. La maîtrise des techniques de microchirurgicales est
          indispensable pour toute opération de chirurgie de la main.
        </P>
        <LinkList>
          <StyledLink to="/chirurgie-main/arthrose-des-doigts/">
            Arthrose des doigts
          </StyledLink>
          <StyledLink to="/chirurgie-main/doigt-ressaut/">
            Doigt à ressaut
          </StyledLink>
          <StyledLink to="/chirurgie-main/entorse-pouce/">
            Entorse du pouce
          </StyledLink>
          <StyledLink
            activeClassName="active"
            to="/chirurgie-main/maladie-dupuytren/"
          >
            Maladie de Dupuytren
          </StyledLink>
          <StyledLink to="/chirurgie-main/kyste-mucoide-doigt/">
            Kyste mucoïde du doigt
          </StyledLink>
          <StyledLink to="/chirurgie-main/plaie-main/">
            Plaie de la main
          </StyledLink>
          <StyledLink to="/chirurgie-main/rhizarthrose/">
            Rhizarthrose : Arthrose du pouce
          </StyledLink>
          <StyledLink to="/chirurgie-nerfs/syndrome-canal-carpien/">
            Syndrome du canal carpien
          </StyledLink>
          <StyledLink to="/chirurgie-main/urgence-main/">
            Urgence de la main
          </StyledLink>
        </LinkList>
        <LinkButton to="/chirurgie-main/" title="Chirurgien de la main à Paris">
          En savoir plus
        </LinkButton>
      </TabPanel>
    </Tabs>
  </Wrapper>
)

const Wrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 2.5em auto 1em;
  padding: 1.5em 1.5em 1em 1.5em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    padding: 1em;
  }
`

const H2 = styled.h2`
  text-align: center;
  padding-top: 0.25em;
  font-weight: 400;
  font-size: 2.4em;
  margin-block-start: 0;
  span {
    font-weight: 800;
  }
  @media (max-width: 678px) {
    font-size: 2.2em;
  }
`

const Cat = styled.div`
  font-size: 1.4em;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  font-variant: small-caps;
  letter-spacing: 0.03em;
  font-weight: 600;
  width: 45%;
  margin: 0 auto;
  padding-bottom: 6px;
  @media (max-width: 678px) {
    width: 100%;
    font-size: 1.2em;
  }
`

const P = styled.p`
  font-size: 1.1em;
  line-height: 1.7em;
  @media (max-width: 678px) {
    font-size: 1em;
  }
`

const LinkList = styled.div`
  width: 80%;
  text-align: center;
  margin: 15px auto 10px;
  @media (max-width: 678px) {
    width: 100%;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #d69c31;
  border-botton: 0.08em solid #5a5a5a;
  font-weight: 500;
  font-size: 0.94em;
  ::after {
    content: "•";
    color: #171717;
    margin: 0 5px 0;
  }
  :last-of-type {
    ::after {
      content: "";
    }
  }
  :hover,
  :focus {
    color: #171717;
  }
  @media (max-width: 678px) {
    display: block;
    line-height: 1.45em;
    margin-bottom: 10px;
    ::after {
      content: none;
    }
  }
`

const LinkButton = styled(Link)`
  display: block;
  margin: 20px auto 0;
  text-align: center;
  font-weight: 600;
  width: 125px;
  font-size: 14px;
  letter-spacing: 0em;
  background-color: black;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(218, 218, 218);
  border-image: initial;
  padding: 0.35em 0.55em;
  border: 1px solid black;
  :focus,
  :hover {
    border: 1px solid #d69c31;
    background-color: white;
    color: #d69c31;
    box-shadow: rgba(230, 174, 72, 0.15) 0px 1px 7px;
    outline: none;
  }
`
